import React, { useMemo } from 'react'
import { track } from 'analytics'
import { processError } from 'helpers'

import { Button } from 'components/inputs'

import { useUnskipSubscription } from './util'
import messages from './messages'


const UnskipButton: React.FunctionComponent<UnskipButton.Props> = (props) => {
  const {
    size = 56, width, style = 'primary',
    title,
    placement,
    fullWidthOnMobile,
    'data-testid': dataTestId = 'unskipButton',
    isRedirectRequired,
    onSuccess,
  } = props

  const { monthsDuration, handleUnskipSubscription, isSubmitting, isFetching } = useUnskipSubscription({ placement, isRedirectRequired })

  const buttonTitle = useMemo(() => title || {
    ...messages.title,
    values: { monthsDuration },
  }, [ title, monthsDuration ])

  const handleClick = async () => {
    try {
      track('Cancel skip click')
      await handleUnskipSubscription()

      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }
    catch (error) {
      processError(error)
    }
  }

  return (
    <Button
      size={size}
      title={buttonTitle}
      style={style}
      width={width}
      loading={isSubmitting || isFetching}
      fullWidthOnMobile={fullWidthOnMobile}
      onClick={handleClick}
      data-testid={dataTestId}
    />
  )
}


export default React.memo(UnskipButton)
